
import { defineComponent } from 'vue';
import { PromptElementType, PROMPT_ELEMENT_TYPES, PROMPT_ELEMENT_TYPES_SEPARATORS } from '@/lib/enum/promptElementType';
import { PromptElement } from '@/lib/promptElement';
import prompt from '@/lib/prompt';
import BasePromptElement from '../base/PromptElement.vue';

/**
 * A list of all available prompt elements as specified in `PROMPT_ELEMENT_TYPES` (`lib/enum/promptElementType.ts`).
 *
 * Prompt elements can be added to the prompt by clicking on them.
 */
export default defineComponent({
  name: 'PromptElements',
  data() {
    return {
      elements: PROMPT_ELEMENT_TYPES,
    };
  },
  components: {
    BasePromptElement,
  },
  methods: {
    /**
     * Adds a prompt element to the prompt.
     *
     * @param index the index of the prompt element's type in `PROMPT_ELEMENT_TYPES` (`lib/enum/promptElementType.ts`).
     */
    pushToPrompt(index: number) {
      prompt.state().push(new PromptElement(PROMPT_ELEMENT_TYPES[index]));
    },
    /**
     * Whether to add a separator before the given element type, as specified in `PROMPT_ELEMENT_TYPES_SEPARATORS`
     * (`lib/enum/promptElementType.ts`).
     */
    separate(element: PromptElementType) {
      return PROMPT_ELEMENT_TYPES_SEPARATORS.includes(element.name);
    }
  },
});
