
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { defineComponent } from 'vue';
import prompt from '@/lib/prompt';
import { UniquePromptElement } from '@/lib/promptElement';
import IconButton from '../ui/IconButton.vue';

/**
 * Merges two lists of prompt elements that are separated by a carriage return (`\r`).
 *
 * The carriage return causes the elements in the second list to (partly) overlay the elements in the first list. This
 * function will return a single list of prompt elements containing elements from both input lists as they would be
 * displayed in the prompt.
 *
 * @param part1 the first list of prompt elements before the carriage return
 * @param part2 the second list of prompt elements after the carriage return
 */
function mergeCrPartitions(part1: UniquePromptElement[], part2: UniquePromptElement[]): UniquePromptElement[] {
  // calculates the number of characters that are visible in the preview of the given element.
  const previewLength = (element: UniquePromptElement) => element.data.type.preview(element.data.parameters).length;

  // the merged list contains all elements from the second partition as they are not overwritten by the first partition
  // we will add further elements from the first partition if the second one is short enough to not overwrite them
  const merged = part2;

  // the (remaining) number of characters that are overwritten by the second partition
  let overlaid = part2.map(previewLength).reduce((a, b) => a + b, 0);
  console.log('hi1');

  part1.forEach((element) => {
    if (overlaid === 0) {
      // we have reached the end of the second partition, so elements from the first partition become visible from now
      merged.push(element);
      return;
    }
    if (previewLength(element) <= overlaid) {
      // element is completely overwritten, so skip it
      overlaid -= previewLength(element);
      return;
    }

    // modify the preview because only a part of the element is visible
    const modifiedPreview = element.data.type.preview(element.data.parameters).slice(overlaid);
    const modifiedPreviewElement = {
      ...element,
      data: {
        ...element.data,
        type: {
          ...element.data.type,
          preview: () => modifiedPreview,
        },
      },
    };
    merged.push(modifiedPreviewElement);

    overlaid = 0;
  });

  console.log('hi2');
  console.log(merged);
  return merged;
}

// export function bsSlicer(str:string, backspaces:number) {
//   // if (backspaces > 0) {
//   //   return bsSlicer
//   // }
//   return str.slice(0, str.length - backspaces);
// }

/**
 * An example preview of the prompt as specified in the `preview` attributes in `PROMPT_ELEMENT_TYPES`
 * (`lib/enum/promptElementType.ts`) and according to the configured display attributes.
 */
export default defineComponent({
  name: 'PromptPreview',
  components: {
    IconButton,
  },
  data() {
    return {
      /**
       * Whether the preview is displayed in light mode.
       *
       * The default is dark mode.
       */
      light: false,
    };
  },
  computed: {
    /**
     * The prompt elements that are currently visible in the preview after handling carriage returns (`\r`).
     */
    elements: () => {
      const elements = [...prompt.state().elements];
      // split elements on carriage returns
      const crPartitions = [[]] as UniquePromptElement[][];
      while (elements.length > 0) {
        const element = elements.shift()!;
        if (element.data.type.preview(element.data.parameters) === '\r') {
          crPartitions.push([]);
        } else {
          crPartitions[crPartitions.length - 1].push(element);
        }
      }
      // crPartitions.forEach(partition => {
      
      for (let i = 0; i < crPartitions.length; i+=1) {  
        const newpartition = [] as UniquePromptElement[];
        for (let index = 0; index < crPartitions[i].length; index += 1) {
          const element = crPartitions[i][index];
          element.data.backspaces = 0;

          if (element.data.type.preview(element.data.parameters) === '\b') {
            // backspace at beginning of line/partition is ignored
            if (newpartition.length > 0) {
              newpartition[newpartition.length - 1].data.backspaces += 1;
            }
          } else {
            // console.log(element);
            newpartition.push(element);
          }

        }
        crPartitions[i] = newpartition;
      }

      // merge partitions from left to right as the third partition may overlay elements from the second partition which
      // again may overlay elements from the first partition
      return crPartitions.reduce(mergeCrPartitions);
    },
  },
  methods: {
    /**
     * Toggles the preview between light and dark mode.
     */
    togglePreview() {
      this.light = !this.light;
    },
    bsSlicer(str:string, backspaces:number) {
      // const str = this. .type.preview(this.data.parameters)
      return str.slice(0, str.length - backspaces);
    }
  },
  updated() {
    // restart all blinking animations so that they are in sync
    document.querySelectorAll('.preview-blink').forEach((element) => {
      const htmlElement = element as HTMLElement;
      htmlElement.style.animation = 'none';
      setTimeout(() => {
        // use animation from css file again
        htmlElement.style.animation = '';
      }, 100);
    });
  },
});
