
import { defineComponent } from 'vue';
import prompt from '@/lib/prompt';
import SiteHeader from './components/SiteHeader.vue';
import ElementSelection from './components/elementselection/ElementSelection.vue';
import PromptElements from './components/prompteditor/PromptElements.vue';
import ElementProperties from './components/properties/ElementProperties.vue';
import PS1Variable from './components/output/PS1Variable.vue';
import PromptPreview from './components/output/PromptPreview.vue';
import darkMode from './lib/darkMode';

export default defineComponent({
  name: 'App',
  components: {
    SiteHeader,
    ElementSelection,
    PromptElements,
    ElementProperties,
    PS1Variable,
    PromptPreview,
  },
  computed: {
    /**
     * Returns 'hidden' if the prompt is empty, 'visible' otherwise.
     *
     * This string can be used as the value of the CSS `visibility` property to hide or show elements depending on
     * whether the prompt is empty.
     */
    visibleState(): 'hidden' | 'visible' {
      return prompt.refs().elements.value.length === 0 ? 'hidden' : 'visible';
    },
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
});
