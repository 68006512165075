
import { defineComponent } from 'vue';
import Popper from 'vue3-popper';
import prompt from '@/lib/prompt';
import { Color } from '@/lib/enum/color';
import { PromptElement } from '@/lib/promptElement';
import darkMode from '@/lib/darkMode';
import EmptyState from '../base/EmptyState.vue';
import ColorPicker from '../ui/ColorPicker.vue';
import IconButton from '../ui/IconButton.vue';

/**
 * Settings for the element's properties such as parameters, colors and display attributes.
 */
export default defineComponent({
  name: 'ElementProperties',
  components: {
    ColorPicker,
    Popper,
    EmptyState,
    IconButton,
  },
  data() {
    return {
      /**
       * The if of the currently selected element, if any.
       *
       */
      selected: prompt.refs().selected,
      /**
       * The currently selected element, if any.
       *
       * Setting any of the properties will modify this (and only this) element.
       */
      element: prompt.refs().selectedElement,
    };
  },
  computed: {
    /**
     * Whether the properties section is empty, i.e. no element is selected.
     *
     * This will also return `false` if the prompt is empty because we don't want to ask the user to select an element
     * when there are none.
     */
    empty(): boolean {
      return prompt.refs().elements.value.length > 0 && prompt.refs().selected.value === null;
    },
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
  methods: {
    /**
     * Sets the foreground color of the currently selected element.
     *
     * @param color the color to set
     */
    selectFg(color: Color | null) {
      if (this.element) {
        this.element.foregroundColor = color;
      }
    },
    /**
     * Sets the background color of the currently selected element.
     *
     * @param color the color to set
     */
    selectBg(color: Color | null) {
      if (this.element) {
        this.element.backgroundColor = color;
      }
    },
    /**
     * Duplicates the currently selected element and appends it to the prompt.
     */
    duplicate() {
      if (this.element !== null) {
        const element = new PromptElement(this.element.type);
        element.foregroundColor = this.element.foregroundColor;
        element.backgroundColor = this.element.backgroundColor;
        element.attributes = { ...this.element.attributes };
        element.backspaces = this.element.backspaces;
        // element.backspaces = { ...this.element.backspaces};
        element.parameters = { ...this.element.parameters };
        prompt.state().push(element);
      }
    },
  },
});
