
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import prompt from '@/lib/prompt';
import { parsePS1, PromptParserError } from '@/lib/promptParser';
import PromptElement from './AddedPromptElement.vue';
import IconButton from '../ui/IconButton.vue';
import EmptyState from '../base/EmptyState.vue';

/**
 * The list of elements currently added to the prompt.
 */
export default defineComponent({
  name: 'PromptElements',
  data() {
    return {
      elements: prompt.refs().elements,
      ps1input: '',
      ps1inputError: '',
    };
  },
  components: {
    PromptElement,
    draggable,
    IconButton,
    EmptyState,
  },
  computed: {
    /**
     * Whether the prompt is empty.
     */
    empty(): boolean {
      return this.elements.length === 0;
    },
  },
  methods: {
    /**
     * Store which elements are currently added to the prompt to make it available globally.
     */
    storeState() {
      prompt.state().elements = this.elements;
    },
    /**
     * Clear all elements from the prompt.
     */
    clear() {
      prompt.state().clear();
    },
    importPS1() {
      try {
        const promptElements = parsePS1(this.ps1input);
        prompt.state().clear();
        this.ps1inputError = '';
        promptElements.forEach((element) => {
          prompt.state().push(element);
        });
      } catch (err) {
        if (err instanceof PromptParserError) {
          this.ps1inputError = err.message;
        } else {
          throw err;
        }
      }
    },
  },
});
