
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import { Color, COLORS } from '@/lib/enum/color';
import darkMode from '@/lib/darkMode';

/**
 * Color picker for setting the foreground or background color of an element.
 */
export default defineComponent({
  name: 'ColorPicker',
  components: {
    XCircleIcon,
  },
  props: {
    /**
     * The currently chosen color.
     */
    selectedColor: {
      type: Color,
      default: null,
    },
  },
  data() {
    return {
      colors4: COLORS.slice(0, 16),
      colors8: COLORS.slice(16),
    };
  },
  methods: {
    /**
     * Chooses a color.
     *
     *
     * @param color the color to choose or `null` to reset the color
     */
    selectColor(color: Color | null) {
      this.$emit('selectColor', color);
    },
  },
  computed: {
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
});
