
import { defineComponent } from 'vue';
import darkMode from '@/lib/darkMode';

export default defineComponent({
  name: 'BasePromptElement',
  props: {
    /**
     * The label of the element that is displayed in the "Prompt Elements" and the "Your Prompt" sections.
     * Labels are defined in `PROMPT_ELEMENT_TYPES` (`lib/enum/promptElementType.ts`).
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The tooltip that is displayed when hovering over the element.
     * Tooltips are defined in `PROMPT_ELEMENT_TYPES` (`lib/enum/promptElementType.ts`).
     */
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
});
