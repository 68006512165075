
import { defineComponent } from 'vue';
import IconButton from '../ui/IconButton.vue';

/**
 * A deletion icon for removing elements from the prompt.
 */
export default defineComponent({
  name: 'DeleteElementIcon',
  components: {
    IconButton,
  },
});
