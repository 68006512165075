import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fef2e8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    title: _ctx.title,
    type: "button"
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "icon-button" }))
  ], 8, _hoisted_1))
}