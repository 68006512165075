
import { defineComponent } from 'vue';
import darkMode from '@/lib/darkMode';
import IconButton from './ui/IconButton.vue';

/**
 * The site header containing the title.
 */
export default defineComponent({
  name: 'SiteHeader',
  components: {
    IconButton,
  },
  methods: {
    /**
     * Opens the usage instructions in the README.md file on GitHub.
     */
    openHelp(): void {
      window.open(
        'https://github.com/Scriptim/bash-prompt-generator#how-to-use',
        '_blank',
      );
    },
    /**
     * Toggles the dark mode.
     */
    toggleDarkMode(): void {
      darkMode().toggle();
    },
  },
  computed: {
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
});
