
import { defineComponent } from 'vue';
import { CubeTransparentIcon } from '@heroicons/vue/24/outline';
import darkMode from '@/lib/darkMode';

export default defineComponent({
  name: 'BaseEmptyState',
  components: {
    CubeTransparentIcon,
  },
  props: {
    /**
     * Whether the current state is empty.
     * 
     * The component is displayed iff this is `true`.
     */
    empty: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    /**
     * Returns `true` if the dark mode is enabled, `false` otherwise.
     */
    darkMode(): boolean {
      return darkMode().enabled;
    },
  },
});
